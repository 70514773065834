<template>
  <div class="mx-5 my-5">
    <h2>الدفع الإلكتروني</h2>
    <h2 class="mt-5" style="color:blue;float:left;margin: 0 15px;">
      الإجمالي = <span style="color:green;">{{ total_payment }} ر.س</span>
    </h2>
    <v-row style="margin-top:60px;">
      <div class="arabic-card">
        <div class="brands-container">
          <div v-for="(gateway, key, index) in gatewayes" :key="key">
            <label
              class="hy_btn-container"
              @click="selectPaymentOption(index, key)"
            >
              <span v-if="gateway && gateway.label"
                >{{ gateway.label }}</span
              >
              <span v-else>{{ key }}</span>
              <div
                v-for="(brand, bkey) in gateway.brands.split(' ')"
                :key="bkey"
              >
                <div :class="`wpwl-brand-${brand}`" class="wpwl-img"></div>
              </div>

              <input
                class="brand"
                :checked="index == selectedPaymentIndex"
                :data-checkout_id="checkout_id"
                :data-brands="gateway.brands"
                type="radio"
                name="brands"
              />
              <div class="checked"></div>
            </label>
          </div>
          <div id="hyperpay_forms_container"></div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedPaymentIndex: 0,
      total_payment: '',
      paymentLink: "",
      checkout_id: "",
      gatewayes: null, // Initialize this with your gateway data
      formData: {
        package_id: null,
        delivary_start_date: null,
      },
      packageList: [
        {
          id: 1,
          name: "غداء عمل",
        },
        {
          id: 2,
          name: "الباقة الثانية",
        },
        {
          id: 3,
          name: "الباقة الأولى",
        },
      ],
    };
  },
  created() {
    let total_payment= localStorage.getItem('total_payment');
    this.total_payment= total_payment || 0;
    localStorage.removeItem('total_payment');
    this.prepareCheckout();
  },
  methods: {
    async prepareCheckout() {
      try {
        const obj = {
          gateWay: "card",
          amount: 200,
          merchantTransactionId: 1000 * Math.random(1),
        };
        const res = await this.$store.dispatch("payment/prepareCheckout", obj);
        this.gatewayes = {
          card: {
            enabled: true,
            entity_id: "8ac7a4c989f619ad0189f880e64902b9",
            access_token:
              "OGFjN2E0Yzk4OWY2MTlhZDAxODlmODgwNTA1NjAyYjV8NFQ1UEREdEg5UQ==",
            currency: "SAR",
            transaction_type: "DB",
            brands: "VISA",
            label: "Cridet Card",
          },
          applepay: {
            enabled: true,
            entity_id: "8ac7a4c989f619ad0189f880e64902b9",
            access_token:
              "OGFjN2E0Yzk4OWY2MTlhZDAxODlmODgwNTA1NjAyYjV8NFQ1UEREdEg5UQ==",
            currency: "SAR",
            transaction_type: "DB",
            brands: "APPLEPAY",
            label: "apple pay",
          },
          master: {
            enabled: true,
            entity_id: "8ac7a4c989f619ad0189f880e64902b9",
            access_token:
              "OGFjN2E0Yzk4OWY2MTlhZDAxODlmODgwNTA1NjAyYjV8NFQ1UEREdEg5UQ==",
            currency: "SAR",
            transaction_type: "DB",
            brands: "MASTER",
            label: "Master Card",
          },
          amex: {
            enabled: true,
            entity_id: "8ac7a4c989f619ad0189f880e64902b9",
            access_token:
              "OGFjN2E0Yzk4OWY2MTlhZDAxODlmODgwNTA1NjAyYjV8NFQ1UEREdEg5UQ==",
            currency: "SAR",
            transaction_type: "DB",
            brands: "AMEX",
            label: "Amex Card",
          },
          mada: {
            enabled: true,
            entity_id: "8ac7a4c989f619ad0189f881853902bd",
            access_token:
              "OGFjN2E0Yzk4OWY2MTlhZDAxODlmODgwNTA1NjAyYjV8NFQ1UEREdEg5UQ==",
            currency: "SAR",
            transaction_type: "DB",
            brands: "MADA",
            label: "Mada Debit Card",
          },
        };
        this.paymentLink = res.data.callback_url;
        this.checkout_id = res.data.check_out_id;
        setTimeout(() => {
          console.clear();
          this.selectPaymentOption(0, "card");
        }, 100);
      } catch (error) {
        console.error("Error while preparing checkout.", error);
      }
    },
  async initPaymentForm(checkoutId, key) {
    const b = document.getElementById("hyperpay_forms_container");
    b.innerHTML = "";

    if (checkoutId) {
      const c = document.createElement("form");
      c.action = this.paymentLink;
      c.className = "paymentWidgets";
      c.dataset.brands = this.gatewayes[key].brands;

      // Create a script element for wpwlOptions
      const wpwlOptionsScript = document.createElement("script");
      wpwlOptionsScript.text = 'var wpwlOptions = { locale: "ar", style: "plain" };';
      const wpwlScriptSrc = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;

      // Create a script element for paymentWidgets.js
      const paymentWidgetsScript = document.createElement("script");
      paymentWidgetsScript.src = wpwlScriptSrc;
      paymentWidgetsScript.async = true;

      paymentWidgetsScript.onerror = () => {
        console.error("Error loading paymentWidgets.js");
      };

      c.appendChild(wpwlOptionsScript);
      c.appendChild(paymentWidgetsScript);
      b.appendChild(c);
    } else {
      const e = document.createElement("div");
      e.className = "alert-container";
      e.innerHTML =
        '<div class="alert alert-danger alert-white rounded"><div class="icon">x</div><strong>Configuration Error! Please check entity_id and access_token</strong></div>';
      b.appendChild(e);
    }

    b.classList.remove("brand-mada");
  
},



      selectPaymentOption(index, key) {
        this.selectedPaymentIndex = index;
        this.initPaymentForm(this.checkout_id, key);
      },
  },
};
</script>